import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "background": "rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/21%20Albums-pc%20%282%29.png?v=2023-12-16T06:51:55.520Z) 0% 0% /auto repeat scroll padding-box",
        "height": "100%",
        "display": "block",
        "align-content": "stretch",
        "justify-content": "stretch",
        "align-items": "stretch",
        "justify-items": "stretch",
        "width": "100%"
    },
    "index": {
        "background": "rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/21%20Albums-pc%20%282%29.png?v=2023-12-16T06:51:55.520Z) 0% 0% /auto repeat scroll padding-box",
        "height": "100%",
        "display": "block",
        "align-content": "stretch",
        "justify-content": "stretch",
        "align-items": "stretch",
        "justify-items": "stretch",
        "width": "100%"
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
